import React, {Col, Row} from "antd";
import style from "./BigColumn.module.css";
import PropTypes from "prop-types";

export const BigColumn = ({icon, iconSize = "100px", name, subtitle, extra}) => {

    return <Row align="middle" style={{padding: "10px"}}>
        <Col flex={iconSize}>{icon}</Col>
        <Col flex="auto">
            <Row justify="space-between" align="middle">
                <Col>
                    <div className={style.title}>{name}</div>
                    <div className={style.subtitle}>
                        {subtitle}
                    </div>
                </Col>
                <Col>{extra}</Col>
            </Row>
        </Col>
    </Row>

}

BigColumn.propTypes = {
    icon: PropTypes.object.isRequired,
    iconSize: PropTypes.string,
    name: PropTypes.string,
    subtitle: PropTypes.string,
    extra: PropTypes.node
}