import React, {useEffect, useState} from "react";
import {Table} from "antd";
import {Resizable} from "react-resizable";
import ReactDragListView from "react-drag-listview";
import PropTypes from "prop-types";

const ResizableTitle = (props) => {
    const {onResize, width, ...restProps} = props;

    if (!width) {
        return <th {...restProps} />;
    }

    return (
        <Resizable
            width={width}
            height={0}
            handle={
                <span
                    className="react-resizable-handle"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    onKeyDown={(e) => {
                        e.stopPropagation()
                    }}
                />
            }
            onResize={onResize}
            draggableOpts={{enableUserSelectHack: false}}
        >
            <th {...restProps} />
        </Resizable>
    );
};

ResizableTitle.propTypes = {
    onResize: PropTypes.func.isRequired,
    width: PropTypes.number
}

const ExportMappingTableComponent = ({data, columns, onMoved}) => {

    const [internalColumns, setInternalColumns] = useState(undefined);

    const dragProps = {
        onDragEnd(fromIndex, toIndex) {
            const columns = [...internalColumns];
            const item = columns.splice(fromIndex, 1)[0];
            columns.splice(toIndex, 0, item);
            onMoved(fromIndex, toIndex);
            setInternalColumns(columns);
        },
        nodeSelector: "th",
        handleSelector: ".dragHandler",
        ignoreSelector: "react-resizable-handle"
    };


    const components = {
        header: {
            cell: ResizableTitle
        }
    };

    useEffect(() => {

        const ress = columns.map((col, index) => ({
            ...col,
            onHeaderCell: (column) => ({
                width: column.width,
                onResize: handleResize(index)
            })
        }));

        setInternalColumns(ress);

    }, [columns]);

    const handleResize = (index) => (e, { size }) => {
        setInternalColumns((columns) => {
            const nextColumns = [...columns];
            nextColumns[index] = {
                ...nextColumns[index],
                width: size.width
            };
            return nextColumns;
        });
    };

    return (
        <ReactDragListView.DragColumn {...dragProps}>
            <Table
                size={"small"}
                style={{minHeight: '80vh', backgroundColor: '#FAFAFA'}}
                bordered
                scroll={{
                    y: 'calc(80vh - 4em)',
                    x: 1300,
                }}
                pagination={false}
                components={components}
                columns={internalColumns}
                dataSource={data}
            />
        </ReactDragListView.DragColumn>)
}

export default ExportMappingTableComponent;

ExportMappingTableComponent.propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    onMoved: PropTypes.func.isRequired
}