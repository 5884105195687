import React, {useEffect, useState} from 'react';
import {exportService} from "../../../../../../services/exportService";
import {Alert} from "antd";


export const ExportStatus = ({exportId, exportSnapshotId, onFinish}) => {

    const [jobStatus, setJobStatus] = useState(null);

    useEffect(() => {
        const checkJobStatus = async () => {
            exportService.fetchExportSnapshot(exportId, exportSnapshotId).then(response => {
                if (response.data.state !== 'STARTED') {
                    clearInterval(intervalId);
                    setJobStatus('finished');
                    onFinish();
                }
            }).catch(error => {
                console.error('Error checking job status:', error);
            });
        };

        // Call the function to check job status when the component mounts
        checkJobStatus();

        // Optionally, set up an interval to check the status periodically
        const intervalId = setInterval(() => {
            checkJobStatus();
        }, 5000); // Check every 5 seconds, for example

        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []); // Empty dependency array ensures this effect runs once when the component mounts

    return (
        <div>
            {jobStatus === 'finished' ? (
                <></>
            ) : (
                <Alert message={`Export is in progress`} description={"This operation may take a while, depending on the source system."} type="info" showIcon />
            )}
        </div>
    );
};

export const ExportStatusFinished = ({documentPublicUrl}) => {

    const [ isAlertVisible, setIsAlertVisible ] = React.useState(true);

    const description = () => {
        if (documentPublicUrl) {
            return <div>The export operation has been successfully completed. You can now view the exported data <a href={documentPublicUrl} target={"_blank"} rel="noreferrer">here</a>.</div>
        } else {
            return <></>
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setIsAlertVisible(false);
        }, 15000);
    }, []);

    if (isAlertVisible) {
        return <Alert
            message={`Export has been finished`}
            description={description()}
            type="success"
            closable={true}
            showIcon
        />
    } else {
        return <></>
    }

}