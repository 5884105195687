import React from "react";
import {useHistory} from "react-router-dom";
import {Spacer} from "../../../../../../components/DesignSystem/Spacer/Spacer";
import {Header} from "../../../../../../components/DesignSystem/Header/Header";
import {HeaderItem} from "../../../../../../components/DesignSystem/Header/HeaderItem";
import {UpperCaseText} from "../../../../../../components/DesignSystem/Typography/UpperCaseText/UpperCaseText";
import {CustomButton} from "../../../../../../components/DesignSystem/CustomButton/CustomButton";
import {ExportIcon} from "../../../../../../components/Export/ExportIcon/ExportIcon";
import {Col, Row, message, Button, Tooltip, Flex} from "antd";
import {Title} from "../../../../../../components/DesignSystem/Typography/Title/Title";
import {exportService} from "../../../../../../services/exportService";
import {ExportDetailHeaderSubTitle} from "../new/ExportDetailHeaderSubTitle";
import SyncOutlined from "@ant-design/icons/lib/icons/SyncOutlined";
import {RunExportButton} from "../../../../../../components/ExportDetail/table/RunExportButton";
import {InlineEdit} from "../../../../../../components/DesignSystem/InlineEdit/InlineEdit";
import {getErrorFromResponse} from "../../../../../../utils/error.utils";
import PropTypes from "prop-types";

export const ExportSnapshotsDetailHeader = ({
                                                companySubscriptionState,
                                                children, exportDefinition,
                                                subscriptionLimit,
                                                statistics, onChangedName, onStartExport,
                                                onRefresh,
                                                editable = false
                                            }) => {

    let history = useHistory();

    const handleActionMenuClick = (event) => {
        if (event === 'edit') {
            history.push(`/exports/${exportDefinition.id}/edit`);
        }
    }

    const handleNameChange = (value) => {
        exportService.updateExportName(exportDefinition.id, value).then(() => {
            message.info("Export name has been updated");
            onChangedName();
        }).catch(error => {
            message.error("Export name hasn't been updated. " + getErrorFromResponse(error));
        })
    }

    return (
        <>
            <Spacer/>
            <Row align="middle">
                <Col sm={16}>
                    <Flex>
                        <ExportIcon size={"large"} source={exportDefinition.source} target={exportDefinition.target}/>
                        <span>
                        <Title>
                            <InlineEdit onChange={handleNameChange} value={exportDefinition.name}/>
                        </Title>
                        <ExportDetailHeaderSubTitle state={exportDefinition.definitionState}
                                                    target={exportDefinition.target}
                                                    source={exportDefinition.source}
                                                    documentPublicUrl={exportDefinition.documentPublicUrl}/>
                    </span>
                    </Flex>
                </Col>
                <Col sm={8}>
                    <div style={{textAlign: "right"}}>
                        <RunExportButton record={exportDefinition} subscriptionLimit={subscriptionLimit}
                                         companySubscriptionState={companySubscriptionState} onClick={onStartExport}/>{" "}
                        <CustomButton type="dashed" onClick={() => handleActionMenuClick('edit')}
                                      disabled={companySubscriptionState !== 'OK' || !editable}>Edit</CustomButton>{" "}
                    </div>
                </Col>
            </Row>
            <Spacer size={"large"}/>
            <Header>
                <Row>
                    <Col sm={20}>
                        {exportDefinition.object && (<HeaderItem label={"Object"} value={<UpperCaseText text={exportDefinition.object}/>}/>)}
                        {statistics && (<HeaderItem label={"Used runs"} value={statistics?.runsUsed}/>)}
                        {statistics && (<HeaderItem label={"Exported rows"} value={statistics?.rowsUsed}/>)}
                        {statistics && (<HeaderItem label={"Failed runs"} value={statistics?.failedRuns}/>)}
                    </Col>
                    <Col sm={4} style={{textAlign: "right"}}>
                        <Tooltip title={"Refresh table"}>
                            <Button icon={<SyncOutlined/>} type="text" onClick={() => onRefresh()}/>
                        </Tooltip>
                    </Col>
                </Row>
            </Header>
            <Spacer/>
            {children}
        </>
    )

}

ExportSnapshotsDetailHeader.propTypes = {
    editable: PropTypes.bool,
    companySubscriptionState: PropTypes.string,
    children: PropTypes.node,
    exportDefinition: PropTypes.object,
    subscriptionLimit: PropTypes.number,
    statistics: PropTypes.object,
    onChangedName: PropTypes.func,
    onStartExport: PropTypes.func,
    setVisible: PropTypes.func,
    onRefresh: PropTypes.func
}