import React from "react";
import {Spacer} from "../../../../../../components/DesignSystem/Spacer/Spacer";
import {CustomButton} from "../../../../../../components/DesignSystem/CustomButton/CustomButton";
import {ExportIcon} from "../../../../../../components/Export/ExportIcon/ExportIcon";
import {Col, Row, message, Flex} from "antd";
import {Title} from "../../../../../../components/DesignSystem/Typography/Title/Title";
import {InlineEdit} from "../../../../../../components/DesignSystem/InlineEdit/InlineEdit";
import {exportService} from "../../../../../../services/exportService";
import {ExportDetailHeaderSubTitle} from "./ExportDetailHeaderSubTitle";
import {RunExportButton} from "../../../../../../components/ExportDetail/table/RunExportButton";
import {getErrorFromResponse} from "../../../../../../utils/error.utils";
import PropTypes from "prop-types";
export const EditExportDetailHeader = ({exportDefinition, subscriptionLimit, companySubscriptionState, onChangedName, onStartExport, onSeeDetails}) => {

    const handleNameChange = (value) => {
        exportService.updateExportName(exportDefinition.id, value).then(() => {
            message.info("Export name has been updated");
            onChangedName();
        }).catch(error => {
            message.error("Export name hasn't been updated. " + getErrorFromResponse(error));
        })
    }

    return (
        <>
            <Spacer/>
            <Row align="middle">
                <Col sm={16}>
                    <Flex>
                        <ExportIcon size={"large"} source={exportDefinition.source} target={exportDefinition.target}/>
                        <span>
                        <Title>
                            <InlineEdit onChange={handleNameChange} value={exportDefinition.name}/>
                        </Title>
                        <ExportDetailHeaderSubTitle state={exportDefinition.definitionState}
                                                    exportId={exportDefinition.id}
                                                    executionType={exportDefinition.executionType}
                                                    source={exportDefinition.source}
                                                    target={exportDefinition.target}
                                                    documentPublicUrl={exportDefinition.documentPublicUrl}/>
                    </span>
                    </Flex>
                </Col>
                <Col sm={8}>
                    <div style={{textAlign: "right", paddingRight: "15px"}}>
                        <RunExportButton record={exportDefinition} subscriptionLimit={subscriptionLimit} companySubscriptionState={companySubscriptionState} onClick={() => onStartExport(exportDefinition.id)}/>{' '}
                        {exportDefinition.id && (<CustomButton type="dashed" onClick={() => onSeeDetails(exportDefinition.id)}>See details</CustomButton>)}
                    </div>
                </Col>
            </Row>
            <Spacer size={"large"}/>
        </>
    )

}

EditExportDetailHeader.propTypes = {
    exportDefinition: PropTypes.object.isRequired,
    subscriptionLimit: PropTypes.number.isRequired,
    companySubscriptionState: PropTypes.string.isRequired,
    onChangedName: PropTypes.func.isRequired,
    onStartExport: PropTypes.func.isRequired,
    onSeeDetails: PropTypes.func.isRequired
}