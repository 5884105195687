import React, {useState, useEffect} from "react";
import {message, Spin} from "antd";
import {ConnectionsTable} from "./table/ConnectionsTable";
import {connectionService} from "../../services/connectionService";
import {getErrorFromResponse} from "../../utils/error.utils";
import {oauthService} from "../../services/oauthService";
import PropTypes from "prop-types";
import {processStepService} from "../../services/processStepService";

export const Connections = ({onEditConnection, companySubscriptionState, renderEmpty}) => {

    const [loadingContext, setLoadingContext] = useState({loading: false, tip: undefined});
    const [data, setData] = useState([]);

    const [screenSize, setScreenSize] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
    });

    const setDimension = () => {
        setScreenSize({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight
        })
    }

    useEffect(() => {
        loadConnections();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const loadConnections = () =>{
        setLoadingContext({loading: true});
        connectionService.fetchAllConnections().then(response => {
            setData(response.data);
        }).finally(() => {
            setLoadingContext({loading: false});
        });
    }

    useEffect(() => {
        window.addEventListener('resize', setDimension);

        return(() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize])

    const handleSelect = (action, record) => {

        if (action === 'delete') {

            setLoadingContext({loading: true});
            connectionService.deleteConnection(record.id).then(() => {
                message.info(`Connection ${record.name} has been deleted.`);
                loadConnections();
            }).catch(error => {
                message.error(getErrorFromResponse(error));
            }).finally(() => {
                setLoadingContext({loading: false});
            });

        } else if (action === 'edit') {
            onEditConnection(record.id, record.type);
        } else if (action === 'test') {
            testConnection(record);
        } else if (action === 'revoke') {
            revokeConnection(record);
        } else if (action === 'refresh_token') {

            let height = 600;
            let width = 800;
            const left = (screenSize.dynamicWidth - width) / 2;
            const top = (screenSize.dynamicHeight - height) / 2;

            if (record.type === 'google-drive' || record.type === 'google-sheets' || record.type === 'ms-excel' || record.type === 'pipedrive') {
                oauthService.getOauthCode(record.type, record.id).then(response => {
                    window.open(response.data.url, "center window", 'resizable = yes, width=' + width + ', height=' + height + ', top=' + top + ', left=' + left);
                })
            } else {
                alert(`${record.type} is not supported`);
            }
        }
    }

    const revokeConnection = (record) => {

        setLoadingContext({loading: true});

        processStepService.getProcessStepData("pipedrive", "revoke", {connection: record.id}).then(() => {
            message.info(`Connection ${record.name} has been revoked.`);
            loadConnections();
        }).catch(error => {
            message.error(getErrorFromResponse(error));
        }).finally(() => {
            setLoadingContext({loading: false});
        });

    }

    const testConnection = (record) => {

        setLoadingContext({loading: true})
        connectionService.testConnection(record.id).then(response => {

            const {error, successful} = response.data;

            if (successful) {
                message.success(`The test of ${record.name} was successful`);
            } else {
                message.error(`The test of ${record.name} connection failed. ${error.message}`);
            }


        }).finally(() => {
            setLoadingContext({loading: false, tip: undefined});
        })
    }

    const handleNameChange = (value, id) => {
        setLoadingContext({loading: true});
        connectionService.updateConnectionName(id, value).then(() => {
            message.info("Connection name has been updated");
            loadConnections();
        }).catch(error => {
            message.error("Connection name hasn't been updated. " + getErrorFromResponse(error));
        }).finally(() => {
            setLoadingContext({loading: false});
        });
    }

    if (companySubscriptionState === undefined) {
        return "companySubscriptionState is missing";
    }

    return <Spin spinning={loadingContext.loading} tip={loadingContext.tip}>
            <ConnectionsTable data={data} renderEmpty={renderEmpty} handleSelect={handleSelect} handleNameChange={handleNameChange} companySubscriptionState={companySubscriptionState}/>
        </Spin>
}

Connections.propTypes = {
    onEditConnection: PropTypes.func.isRequired,
    companySubscriptionState: PropTypes.string.isRequired,
    renderEmpty: PropTypes.func.isRequired
}


