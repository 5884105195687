import React, {Form} from "antd";
import {useEffect, useState} from "react";
import {FormCard} from "../DesignSystem/FormCard/FormCard";
import {SelectStepType} from "../StepType/SelectStepType";
import {stepTypeService} from "../../services/stepTypeService";
import {exportService} from "../../services/exportService";
import {useHistory} from "react-router-dom";

export const ExportSelectSourceStep = () => {

    let history = useHistory();

    const [stepTypes, setStepTypes] = useState([]);

    useEffect(() => {
        stepTypeService.fetchStepTypeInput().then(response => {
            setStepTypes(response.data);
        })

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSelectStepType = (type) => {

        const selectedStepType = stepTypes.find(stepType => stepType.type === type);

        return exportService.createExportObjectStep({type: selectedStepType.type,
            name: selectedStepType.name,
            direction: selectedStepType.direction
        }).then(response => {
            history.push(`/exports/${response.data.export.id}/edit`);
        });

    }

    return <FormCard title={"Source system"} detail={"Please select the source system from which you want to export data."}>
        <Form layout={"vertical"}>
            <Form.Item>
                <SelectStepType items={stepTypes} onSelect={handleSelectStepType}/>
            </Form.Item>
        </Form>
    </FormCard>

}