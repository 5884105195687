import React from "antd";
import {SilverTitle} from "../../../../../../components/DesignSystem/Typography/SilverTitle/SilverTitle";
import {ExportDocumentPublicUrl} from "../../../../../../components/Export/ExportDocumentPublicUrl";
import PropTypes from "prop-types";
import {useHistory} from "react-router-dom";

export const ExportDetailHeaderSubTitle = ({source, target, documentPublicUrl}) => {

    return <SilverTitle>from <span style={{color: "black"}}>{getSourceName(source)}</span> to <span style={{color: "black"}}>{getTargetName(target)}</span>
        <ExportDocumentPublicUrl documentPublicUrl={documentPublicUrl}/>
    </SilverTitle>

}

ExportDetailHeaderSubTitle.propTypes = {
    state: PropTypes.string.isRequired,
    target: PropTypes.string,
    documentPublicUrl: PropTypes.string

}

export const ScheduledButton = ({exportId, executionType}) => {

    const history = useHistory();

    const onClick = () => {
        history.push(`/exports/${exportId}/schedule`);
    }

    return <a onClick={onClick}>{executionType === "SCHEDULED" ? "Scheduled" : "Schedule"}</a>

}

export const getTargetName = (target) => {
    switch (target) {
        case 'google-sheets':
            return 'Google Sheets';
        case 'google-drive':
            return 'Google Drive';
        case 'sftp-upload-file':
            return 'sftp';
        case 's3-upload-file':
            return 'AWS S3';
        case 'ms-excel':
            return 'MS Excel';
        default:
            return target;
    }
}

export const getSourceName = (source) => {
    switch (source) {
        case 'pipedrive':
            return 'Pipedrive';
        case 'jira':
            return 'Jira';
        default:
            return source;
    }
}