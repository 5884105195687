import React from "react";
import {CustomButton} from "../CustomButton/CustomButton";
import ActionDropdown from "../ActionDropdown/ActionDropdown";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import {ActionHeader} from "../ActionHeader/ActionHeader";
import PropTypes from "prop-types";

export const MODE = {
    READ: "read",
    EDIT: "edit",
};

export const ProcessStepHeader = ({icon, subtitle, title, onClick, mode,
                                      onDelete,
                                      onClose, showDelete = false,
                                      onCustomEditButtonsClick,
                                      customEditButtons = []}) => {

    const handleSelect = (value) => {

        if (value === 'delete') {
            onDelete();
        }

    }

    const getCustomEditButtons = () => {
        return customEditButtons.map(customEditButton => {
            return <span key={customEditButton.key}><CustomButton key={customEditButton.key} onClick={() => onCustomEditButtonsClick(customEditButton.key)}>{customEditButton.title}</CustomButton>{' '}</span>
        });
    }

    const getButtons = () => {

        if (mode === MODE.READ) {
            return <CustomButton onClick={onClick}>Edit</CustomButton>
        } else if (mode === MODE.EDIT && typeof onDelete === 'function' && showDelete) {
            return <><ActionDropdown handleSelect={handleSelect} items={[
                {
                    title: 'Delete',
                    id: 'delete',
                    icon: <DeleteOutlined/>,
                    confirmation: {
                        title: 'Confirmation',
                        content: `Do you really want to delete this step?`
                    }
                }
            ]}>
            </ActionDropdown><CustomButton onClick={onClose}>Close</CustomButton></>
        } else if (mode === MODE.EDIT) {
            return <>
                {getCustomEditButtons()}
                <CustomButton onClick={onClose}>Close</CustomButton>
            </>
        }
    }

    return <ActionHeader icon={icon} title={title} subtitle={subtitle} extra={getButtons()}/>

}

ProcessStepHeader.propTypes = {
    icon: PropTypes.any.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    mode: PropTypes.string.isRequired,
    onDelete: PropTypes.func,
    showDelete: PropTypes.bool,
    onClose: PropTypes.func,
    onCustomEditButtonsClick: PropTypes.func,
    customEditButtons: PropTypes.array
}