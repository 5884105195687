import React from "react";
import {AlertIfMessage} from "../../../DesignSystem/AlertIfMessage/AlertIfMessage";
import {Button, Form, Input} from "antd";
import {Spinner} from "../../../DesignSystem/Spinner/Spinner";
import {Spacer} from "../../../DesignSystem/Spacer/Spacer";
import PropTypes from "prop-types";

export const EditCompanyForm = ({handleSubmit, company, error, loading}) => {

    const [form] = Form.useForm();

    return <Spinner spinning={loading}>
        <AlertIfMessage message={error}/>
        <Form
            layout='vertical'
            form={form}
            onFinish={handleSubmit}
            initialValues={{
                name: company.name
            }}
        >
            <Form.Item name="name" label="Name" rules={[{required: true}]}>
                <Input placeholder="Name"/>
            </Form.Item>
            <Spacer/>
            <Form.Item>
                <Button type="primary" htmlType="submit">Save</Button>
            </Form.Item>
        </Form>
    </Spinner>

}

EditCompanyForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    company: PropTypes.object.isRequired,
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired
}