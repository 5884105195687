import React, {Col, Form, message, Modal, Row, Typography} from "antd";
import {MODE} from "../../DesignSystem/ProcessStep/ProcessStepHeader";
import {ExportMappingTableColumnsSelection} from "./ExportMappingTableColumnsSelection";
import {useEffect, useState} from "react";
import ExportMappingTableComponent from "./ExportMappingTableComponent";
import PropTypes from "prop-types";
import {STEPS} from "../../../views/apps/project/backups/exports/exportFormService";
import {Spacer} from "../../DesignSystem/Spacer/Spacer";

function getDescendantProp(obj, desc) {
    const arr = desc.split(".");
    while (arr.length && (obj = obj[arr.shift()])) ;
    return obj;
}

const convertValue = (name, record) => {

    const value = getDescendantProp(record, name);

    if (value === undefined) {
        return value;
    } else if (typeof value == "boolean") {
        return value.toString();
    } else if (value instanceof Array) {
        return JSON.stringify(value);
    } else if (value instanceof Object) {
        return JSON.stringify(value);
    } else {
        return value;
    }

}

export const ExportMappingTable = ({mapping, preview, fields, onFinish, setMode, onSelected, onRemoved, onColumnMoved, onClose}) => {

    const [selectedFields, setSelectedFields] = useState(0);

    const columns = (mapping) => {
        return mapping.map(item => {
            return {
                width: 100,
                title: <Typography.Text ellipsis={true} title={item.name} className="dragHandler">{item.name}</Typography.Text>,
                dataIndex: item.name,
                render: (text, record) => (
                    <Typography.Text ellipsis={true}>{convertValue(item.name, record)}</Typography.Text>
                )
            }
        })
    }

    useEffect(() => {
        form.setFieldsValue({mapping: mapping});
        if (mapping.length === 0) {
            form.setFields([
                {
                    name: 'mapping',
                    errors: ["At least one field must be selected"],
                }
            ]);
        }
        setSelectedFields(mapping.length);
    }, [mapping]);

    const [form] = Form.useForm();

    const handleFinish = (values) => {

        if (mapping.length === 0) {
            form.setFields([
                {
                    name: 'mapping',
                    errors: ["At least one field must be selected"],
                }
            ]);
            return;
        } else {
            onFinish({mapping: mapping, step: STEPS.EXPORT_MAPPING_STEP}).then(() => {
                message.info("All changes have been applied");
                setMode(MODE.READ);
            })
        }
    }

    const handleCancel = () => {
        onClose();
    }

    return <Modal open={true} width={"large"} closeIcon={false} onOk={handleFinish} onCancel={handleCancel}><Form form={form}
                                                                                                                  initialValues={{mapping: mapping}}
                                                                                                                  style={{minHeight: '75vh'}}
                                                                                                                  onFinish={handleFinish}
    >
        <Row>
            <Col sm={24}><strong>Export fields </strong>({selectedFields} selected fields)</Col>
        </Row>
        <Spacer/>
        <Row gutter={14}>
            <Col sm={4} style={{backgroundColor: '#FAFAFA'}}>
                <ExportMappingTableColumnsSelection fields={fields} mapping={mapping}
                                                    onRemoved={onRemoved}
                                                    onSelected={onSelected}/>
            </Col>
            <Col sm={20}>
                <Form.Item name="mapping">
                    <ExportMappingTableComponent data={preview} columns={columns(mapping)} onMoved={onColumnMoved}/>
                </Form.Item>
            </Col>
        </Row>
    </Form></Modal>

}

ExportMappingTable.propTypes = {
    mapping: PropTypes.array.isRequired,
    preview: PropTypes.array.isRequired,
    fields: PropTypes.array.isRequired,
    onFinish: PropTypes.func.isRequired,
    setMode: PropTypes.func.isRequired,
    onSelected: PropTypes.func.isRequired,
    onRemoved: PropTypes.func.isRequired,
    onColumnMoved: PropTypes.func.isRequired
};