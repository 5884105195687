import React, {Tooltip, Tree} from "antd";
import {useEffect, useState} from "react";
import PropTypes from "prop-types";

const getTitle = (a) => {
    if (a.label === undefined) {
        return a.name
    } else {
        return <Tooltip title={'Name: ' + a.name + '. ' + a.description}>{a.label}</Tooltip>
    }
}
export const ExportMappingTableColumnsSelection = ({fields, mapping, onSelected, onRemoved}) => {

    const [treeData, setTreeData] = useState([]);
    const [defaultCheckedKeys, setDefaultCheckedKeys] = useState([]);

    useEffect(() => {

        if (fields === undefined) {
            return;
        }

        const tree = fields.groups.map(group => {
            return {
                title: group.name,
                key: group.name,
                children: group.properties.map(a => {
                    return {title: getTitle(a), key: a.name, name: a.name, label: a.label, target: a.target}
                })
            }
        })
        setTreeData(tree);
        setDefaultCheckedKeys(mapping.map(s => s.name));

    }, [fields, mapping]);

    const onCheck = (checkedKeys, info) => {

        setDefaultCheckedKeys(checkedKeys);

        if (info.node.hasOwnProperty("children")) {

            if (info.checked === true) {
                const keys = [];
                info.node.children.forEach(a => {
                    keys.push(a.key);
                })
                onSelected(keys.map(s => {
                    return {name: s, label: info.node.label, target: info.node.target}
                }));
            } else {
                const keys = [];
                info.node.children.forEach(a => {
                    keys.push(a.key);
                })
                onRemoved(keys);
            }

        } else if (info.checked === true) {
            onSelected([{name: info.node.key, label: info.node.label, target: info.node.target}]);
        } else {
            onRemoved([info.node.key]);
        }


    };

    return <div style={{maxHeight: '400px', overflow: 'scroll', minHeight: '80vh', backgroundColor: '#FAFAFA'}}>
            <Tree checkable treeData={treeData} onCheck={onCheck} defaultExpandParent={true}
                  defaultCheckedKeys={[...defaultCheckedKeys]}
                  style={{backgroundColor: '#FAFAFA', paddingTop: '10px'}}
                  checkedKeys={[...defaultCheckedKeys]}/>
    </div>

}

ExportMappingTableColumnsSelection.propTypes = {
    fields: PropTypes.object.isRequired,
    mapping: PropTypes.array.isRequired,
    onSelected: PropTypes.func.isRequired,
    onRemoved: PropTypes.func.isRequired
}