import React from "react";
import {Image, Tooltip} from "antd";
import PropTypes from "prop-types";

export const BorderedIcon = ({image, size = "small", tooltip}) => {

    const s = size === 'small' ? 40 : 80;
    const padding = size === 'small' ? "5px" : "20px";

    return <Tooltip title={tooltip}><Image src={image} width={s} height={s} preview={false}
                    style={{border: "1px solid rgba(5, 5, 5, 0.06)", padding: padding, background: "white"}}/></Tooltip>

}

BorderedIcon.propTypes = {
    image: PropTypes.string,
    size: PropTypes.string
}