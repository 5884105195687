import React from "react";
import {Link, Route, useHistory} from "react-router-dom";
import {PageFooter} from "./PageFooter";
import {Button, Col, Layout, Row} from "antd";
import {Header} from "antd/lib/layout/layout";
import {Content} from "antd/es/layout/layout";
import PropTypes from "prop-types";

export const PublicParentPage = ({children, ...rest}) => {

    const history = useHistory();

    const onClick = (e) => {
        if (e.key === '/home') {
            window.location.replace("https://www.apimoon.com/");
        } else if (e.key === '/sign-up') {
            history.push("/sign-up");
        }
    };

    return (<Layout
        style={{
            minHeight: '100vh',
        }}
    >
        <Row align={"center"} style={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'white'
        }}>
            <Col sm={18} xl={18}>
                <Header className={"navbar"}>
                    <Row align={"middle"}>
                        <Col sm={12}>
                            <a href={"https://www.apimoon.com"}>
                                <h5>apimoon</h5>
                            </a>
                        </Col>
                        <Col sm={12} style={{textAlign: 'right'}}>
                            <span style={{width: '120px'}} onClick={() => onClick({key: "/sign-up"})}
                                    className={"button-primary w-button"}>Try it free</span>
                        </Col>
                    </Row>
                </Header>

            </Col>
        </Row>
        <Content style={{backgroundColor: "white"}}>
            <Route
                {...rest}
                render={({location}) => (children)}
            />
        </Content>
        <Row align={"center"} style={{backgroundColor: 'white'}}>
            <Col sm={18}>
                <PageFooter/>
            </Col>
        </Row>
    </Layout>);

};

PublicParentPage.propTypes = {
    children: PropTypes.any
};