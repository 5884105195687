import {RegistrationPage} from "./views/apps/public/RegistrationPage";
import {LoginPage} from "./views/apps/public/LoginPage";
import ProfilePage from "./views/apps/settings/ProfilePage";
import {LogoutPage} from "./views/apps/public/LogoutPage";
import TermsPage from "./views/apps/public/TermsPage";
import {NotificationsPage} from "./views/apps/project/backups/notification/NotificationsPage";
import {CompanyPage} from "./views/apps/settings/CompanyPage";
import MembersPage from "./views/apps/settings/MembersPage";
import ConfirmInvitationPage from "./views/apps/public/ConfirmInvitationPage";
import ConfirmEmailPage from "./views/apps/public/ConfirmEmailPage";
import {ResetPasswordPage} from "./views/apps/public/ResetPasswordPage";
import {ResetPasswordRequestPage} from "./views/apps/public/ResetPasswordRequestPage";
import {UserDeleted} from "./components/apps/public/user-deleted/UserDeleted";
import {CompanyDeleted} from "./components/apps/public/company-deleted/CompanyDeleted";
import {NewExportPage} from "./views/apps/project/backups/exports/new/NewExportPage";
import {ExportSnapshotsDetail} from "./views/apps/project/backups/exports/snapshots/ExportSnapshotsDetail";
import {ConnectionsPage} from "./views/apps/connections/ConnectionsPage";
import {UsagePage} from "./views/apps/usage/UsagePage";
import {BillingPage} from "./views/apps/billing/BillingPage";
import {NewConnectionPage} from "./views/apps/connections/NewConnectionPage";
import {GoogleDriveCallback} from "./views/apps/ouath/GoogleDriveCallback";
import {EditConnectionPage} from "./views/apps/connections/EditConnectionPage";
import {GoogleSheetsCallback} from "./views/apps/ouath/GoogleSheetsCallback";
import {ExportsPage} from "./views/apps/project/backups/exports/ExportsPage";
import {MsExcelCallback} from "./views/apps/ouath/MsExcelCallback";
import {PipedriveCallback} from "./views/apps/ouath/PipedriveCallback";
import {PricingPage} from "./views/apps/public/PricingPage";
import {ContactSalesPage} from "./views/apps/public/ContactSalesPage";

const routes = [
    {
        path: "/connections/oauth/google-drive/callback",
        component: GoogleDriveCallback,
        private: true,
        template: "connection"
    },
    {
        path: "/connections/oauth/google-sheets/callback",
        component: GoogleSheetsCallback,
        private: true,
        template: "connection"
    },
    {
        path: "/connections/oauth/ms-excel/callback",
        component: MsExcelCallback,
        private: true,
        template: "connection"
    },
    {
        path: "/connections/oauth/pipedrive/callback",
        component: PipedriveCallback,
        template: "public",
        private: false
    },
    {
        path: "/exports/new",
        component: NewExportPage,
        private: true,
        template: "default"
    },
    {
        path: "/exports/:exportId/edit",
        component: NewExportPage,
        private: true,
        template: "default"
    },
    {
        path: "/exports/:exportId/snapshots",
        component: ExportSnapshotsDetail,
        private: true,
        template: "default"
    },
    {
        path: "/exports",
        component: ExportsPage,
        private: true,
        template: "default"
    },
    {
        path: "/notifications",
        component: NotificationsPage,
        private: true,
        template: "default"
    },
    {
        path: "/sign-up",
        component: RegistrationPage,
        template: "public"
    },
    {
        path: "/login",
        component: LoginPage,
        template: "public"
    },
    {
        path: "/contact-sales",
        component: ContactSalesPage,
        template: "public"
    },
    {
        path: "/pricing",
        component: PricingPage,
        template: "public"
    },
    {
        path: "/logout",
        component: LogoutPage,
        private: false
    },
    {
        path: "/reset-password-request",
        component: ResetPasswordRequestPage,
        private: false
    },
    {
        path: "/reset-password",
        component: ResetPasswordPage,
        private: false
    },
    {
        path: "/confirm-email",
        component: ConfirmEmailPage,
        private: false
    },
    {
        path: "/invitation-email",
        component: ConfirmInvitationPage,
        private: false
    },
    {
        path: "/user-deleted",
        component: UserDeleted,
        private: false
    },
    {
        path: "/company-deleted",
        component: CompanyDeleted,
        private: false
    },
    {
        path: "/terms",
        component: TermsPage,
        template: "public"
    },
    {
        path: "/users",
        component: MembersPage,
        private: true,
        template: "default"
    },
    // {
    //     path: "/settings/subscriptions",
    //     component: SubscriptionsPage,
    //     private: true,
    //     template: "user"
    // },
    {
        path: "/settings/company",
        component: CompanyPage,
        private: true,
        template: "default"
    },
    {
        path: "/settings",
        component: ProfilePage,
        private: true,
        template: "default"
    },
    {
        path: "/connections/new",
        component: NewConnectionPage,
        private: true,
        template: "connection"
    },
    {
        path: "/connections/edit/:connectionId/:connectionType",
        component: EditConnectionPage,
        private: true,
        template: "connection"
    },
    {
        path: "/connections",
        component: ConnectionsPage,
        private: true,
        template: "default"
    },
    // {
    //     path: "/settings/access-tokens",
    //     component: AccessTokenPage,
    //     private: true,
    //     template: "user"
    // },
    {
        path: "/usage",
        component: UsagePage,
        private: true,
        template: "default"
    },
    {
        path: "/billing",
        component: BillingPage,
        private: true,
        template: "default"
    },
    {
        path: "/",
        component: ExportsPage,
        private: true,
        template: "default"
    }
];

export default routes;