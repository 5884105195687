import React from "react";
import {Spacer} from "../../../../../../components/DesignSystem/Spacer/Spacer";
import {ExportIcon} from "../../../../../../components/Export/ExportIcon/ExportIcon";
import {Col, Row} from "antd";
import {Title} from "../../../../../../components/DesignSystem/Typography/Title/Title";
import {ExportDetailHeaderSubTitle} from "./ExportDetailHeaderSubTitle";

export const NewExportDetailHeader = () => {

    return (
        <>
            <Spacer/>
            <Row align="middle">
                <Col sm={24}>
                    <Row align="middle" gutter={20}>
                        <Col><ExportIcon size={"large"}/></Col>
                        <Col>
                            <Title>
                                Untitled export
                            </Title>
                            <ExportDetailHeaderSubTitle state={'Draft'}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Spacer size={"large"}/>
        </>
    )

}