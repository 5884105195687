export const getErrorFromResponse = (error) => {

    return getError(error.response.data);

}

export const getError = (error) => {

    try {

        if (error.error?.message) {
            return error.error.message;
        } else if (error.message) {
            return error.message;
        } else {
            return error.error;
        }

    } catch (error) {
        return "Error: " + JSON.stringify(error);
    }



}