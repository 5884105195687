import React, {useEffect, useState} from "react";
import {stepTypeService} from "../../services/stepTypeService";
import {Form} from "antd";
import {FormCard} from "../DesignSystem/FormCard/FormCard";
import {processStepService} from "../../services/processStepService";
import {SelectStepType} from "../StepType/SelectStepType";
import PropTypes from "prop-types";

export const Step = ({exportId}) => {

    const [stepTypes, setStepTypes] = useState([]);

    useEffect(() => {
        stepTypeService.fetchStepTypes().then(response => {
            setStepTypes(response.data.filter(a => a.direction === "OUT"));
        })
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSelectStepType = (type) => {

        const selectedStepType = stepTypes.find(stepType => stepType.type === type);

        processStepService.createProcessStepDraft(exportId, selectedStepType.type, selectedStepType.name, selectedStepType.direction).then(response => {
            window.location.reload();
        });

    }

    return <FormCard title={"Export data to"} detail={"Please select a target system where do you want to export your data to"}>
        <Form layout={"vertical"}>
            <Form.Item>
                <SelectStepType items={stepTypes} onSelect={handleSelectStepType}/>
            </Form.Item>
        </Form>
    </FormCard>
}

Step.propTypes = {
    exportId: PropTypes.string
}