import {axiosService} from "../axios";

export const exportService = {

    fetchAllExports: (page, size) => {
        return axiosService.get(`/api/exports?page=${page - 1}&size=${size}`)
    },

    fetchAllExportSnapshots: (exportId, showFailedOnly, page, size) => {
        return axiosService.get(`/api/exports/${exportId}/snapshots?page=${page - 1}&size=${size}&showFailedOnly=${showFailedOnly}`)
    },

    fetchExport: (exportId) => {
        return axiosService.get(`/api/exports/${exportId}`)
    },

    fetchExportSnapshot: (exportId, snapshotId) => {
        return axiosService.get(`/api/exports/${exportId}/snapshots/${snapshotId}`)
    },

    deleteExport: (exportId) => {
        return axiosService.delete(`/api/exports/${exportId}`)
    },

    copyExport: (exportId) => {
        return axiosService.get(`/api/exports/${exportId}/copy`)
    },

    updateExportTriggerStep: (exportId, values) => {
        return axiosService.post(`/api/exports/export-trigger-step/${exportId}`, values);
    },

    createExportObjectStep: (values) => {
        const data = {...values, values: { connection: values.connection, filter: values.filter }};
        return axiosService.post("/api/exports/export-object-step", data);
    },

    updateExportMappingStep: (exportId, values) => {
        const data = {...values, values: { mapping: values.mapping,  }};
        return axiosService.post(`/api/exports/export-mapping-step/${exportId}`, data);
    },

    updateExportName: (exportId, name) => {
        return axiosService.post(`/api/exports/${exportId}/name`, {name: name});
    },

    startExport: (exportId) => {
        return axiosService.get(`/api/exports/${exportId}/start`);
    },

    getNewExport: () => {
        return axiosService.get(`/api/exports/new`);
    },

    download: (exportId, exportSnapshotId) => {
        return axiosService.get(`/api/exports/${exportId}/download/${exportSnapshotId}`)
    },

}