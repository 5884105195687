import React, {Col, Row} from "antd";
import style from "./ActionHeader.module.css";
import PropTypes from "prop-types";

export const ActionHeader = ({icon, title, subtitle, extra}) => {

    return <Row align="middle">
        <Col flex="50px">{icon}</Col>
        <Col flex="auto">
            <Row justify="space-between" align="middle">
                <Col>
                    {subtitle && (
                        <div className={style.subtitle}>
                            {subtitle}
                        </div>
                    )}
                    <div className={style.title}>{title}</div>
                </Col>
                <Col>
                    {extra}
                </Col>
            </Row>
        </Col>
    </Row>

}

ActionHeader.propTypes = {
    icon: PropTypes.element,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    extra: PropTypes.element
};