import {axiosService} from "../axios";

export const connectionService = {

    fetchAllConnections: () => {
        return axiosService.get(`/api/connections`)
    },

    fetchConnectionsByType: (type) => {
        return axiosService.get(`/api/connections?type=${type}`)
    },

    createConnection: (type, values) => {
        return axiosService.post(`/api/connections`, { type: type, values: values, access: "PRIVATE"});
    },

    updateConnection: (connectionId, values) => {
        return axiosService.put(`/api/connections/${connectionId}`, {values: values});
    },

    testConnection: (connectionId) => {
        return axiosService.get(`/api/connections/test/${connectionId}`);
    },

    deleteConnection: (connectionId) => {
        return axiosService.delete(`/api/connections/${connectionId}`);
    },

    updateConnectionName: (connectionId, name) => {
        return axiosService.post(`/api/connections/${connectionId}/name`, {name: name});
    },

    updateConnectionAccess: (connectionId, access) => {
        return axiosService.post(`/api/connections/${connectionId}/access`, {access: access});
    },

}