import React from "react";
import {Col, Layout, Row} from "antd";
import {Link} from "react-router-dom";
import {UserMenu} from "../UserMenu/UserMenu";
import {FormattedDate} from "../../DesignSystem/Moment/FormattedDate";
import PropTypes from "prop-types";
import {TopHeaderHelp} from "./TopHeaderHelp";

const {Header} = Layout;

const TopHeader = ({user}) => {

    const showExpiryMessage = () => {
        if (user.companyPlan.companySubscriptionState === 'TRIAL_EXPIRED') {
            return <span style={{color: "white", paddingRight: "10px", verticalAlign: "middle"}}>Your free trial ended on <FormattedDate value={user.companyPlan.currentPeriodEnd}/></span>
        } else if (user.companyPlan.type === 'FREE_TRIAL') {
            return <span style={{color: "white", paddingRight: "10px", verticalAlign: "middle"}}>
                Your free trial will end on <FormattedDate value={user.companyPlan.currentPeriodEnd}/></span>
        } else {
            return <></>
        }
    }

    return <Header>
        <Row>
            <Col sm={2}>
                <Link to="/exports">
                    <img src={"/logo.png"} style={{width: '80px', paddingTop: '18px', paddingLeft: '10px'}} alt="logo"/>
                </Link>
            </Col>
            <Col sm={22} style={{textAlign: "right"}}>
                {/*<NotificationsContainer/>*/}
                {showExpiryMessage()}
                <TopHeaderHelp/>&nbsp;&nbsp;
                <UserMenu user={user}/>
            </Col>
        </Row>
    </Header>

}

export default TopHeader;

TopHeader.propTypes = {
    user: PropTypes.object.isRequired
}