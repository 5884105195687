import React, {Col, Row} from "antd";
import style from "./ActionPanel.module.css";
import PropTypes from "prop-types";

export const ActionPanel = ({header, children}) => {

    return <Row className={style.body}>
        <Col sm={24}>
            {header}
        </Col>
        <Col sm={24}>
            {children}
        </Col>
    </Row>

}

ActionPanel.propTypes = {
    header: PropTypes.element,
    children: PropTypes.element
};