import React, {message} from "antd";
import {useEffect, useState} from "react";
import {ActionPanel} from "../DesignSystem/ActionPanel/ActionPanel";
import {MODE, ProcessStepHeader} from "../DesignSystem/ProcessStep/ProcessStepHeader";
import pluralize from "pluralize";
import {DefinitionState} from "./ExportMainForm";
import {ExportMappingTable} from "./table/ExportMappingTable";
import PropTypes from "prop-types";
import {STEPS} from "../../views/apps/project/backups/exports/exportFormService";

export const ExportMappingStep = ({
                                      title, icon, defaultMode = MODE.READ, subtitle,
                                      onShowEditMode, data, onChange, onFinish, onClose
                                  }) => {

    const [mode, setMode] = useState(defaultMode);
    const [fields, setFields] = useState(undefined);
    const [preview, setPreview] = useState(undefined);
    const [mapping, setMapping] = useState(undefined);

    useEffect(() => {
        setMode(defaultMode);
        fetchSupportedFields();
    }, [defaultMode]);


    useEffect(() => {
        if (data.mapping === undefined || data.mapping === null) {
            setMapping([]);
        } else {
            setMapping(data.mapping);
        }
    }, [data]);

    const handleOnClick = () => {
        onShowEditMode(DefinitionState.ExportMappingStep).then(() => {
            fetchSupportedFields();
            setMode(MODE.EDIT);
        }).catch(e => {
            message.info(e);
        });
    }

    const getTitle = () => {

        if (mapping) {
            return "Export " + pluralize("field", mapping.length, true);
        } else {
            return title;
        }

    }

    const fetchSupportedFields = () => {
        onChange(STEPS.EXPORT_MAPPING_STEP, "fetch-supported-fields", {...data}, null).then(response => {
            if (response.data.preview === undefined || response.data.preview === null) {
                setPreview([]);
            } else {
                setPreview(response.data.preview);
            }
            setFields(response.data.fields);
            setMapping(data.mapping);
        })
    }

    const handleSelected = (values) => {
        setMapping([...mapping, ...values]);
    }

    const handleRemoved = values => {
        values.forEach(value => {
            mapping.splice(mapping.findIndex(i => i.name === value), 1);
        })
        setMapping([...mapping]);
    }

    const handleColumnMoved = (fromIndex, toIndex) => {
        const columns = [...mapping];
        const item = columns.splice(fromIndex, 1)[0];
        columns.splice(toIndex, 0, item);
        setMapping(columns);
    }

    return <ActionPanel
        header={<ProcessStepHeader icon={icon} title={getTitle()}
                                   subtitle={subtitle} mode={mode}
                                   onClick={handleOnClick}
                                   onClose={onClose}/>
        }>
        {mode === MODE.EDIT && mapping !== undefined && (<ExportMappingTable onFinish={onFinish}
                                    setMode={setMode}
                                    preview={preview}
                                    fields={fields}
                                    onRemoved={handleRemoved}
                                    onSelected={handleSelected}
                                    onColumnMoved={handleColumnMoved}
                                    onClose={onClose}
                                    mapping={mapping}/>
        )}
    </ActionPanel>

}

ExportMappingStep.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.object,
    subtitle: PropTypes.string,
    defaultMode: PropTypes.oneOf([MODE.READ, MODE.EDIT]),
    onShowEditMode: PropTypes.func,
    onFinish: PropTypes.func,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    data: PropTypes.object
}