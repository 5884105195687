import {Alert} from "antd";
import React from "react";
import {Link} from "react-router-dom";


export const DeleteLastUserAlert = ({}) => {

    const message = () => {
        return <>There are no other users in your company. </>;
    }

    const description = () => {
        return <>Please go to the <Link to={"/settings/company/"}>Company section</Link> and delete whole company.</>;
    }

    return <Alert
        description={description()}
        message={message()}/>

}