import React, {useEffect, useState} from "react";
import {Spacer} from "../../../components/DesignSystem/Spacer/Spacer";
import {CenteredDesign} from "../../../components/DesignSystem/CenteredDesign/CenteredDesign";
import {PageHeader} from "@ant-design/pro-layout";
import {statisticService} from "../../../services/statisticService";
import {Col, Row, Skeleton, Table} from "antd";
import {SubTitle} from "../../../components/DesignSystem/Typography/SubTitle/SubTitle";
import {SilverTitle} from "../../../components/DesignSystem/Typography/SilverTitle/SilverTitle";
import {FormattedDate} from "../../../components/DesignSystem/Moment/FormattedDate";
import {Link} from "react-router-dom";

export const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 400,
        render: (text, record) => {
            return <Link to={`/exports/${record.exportId}/snapshots`}>{record.name}</Link>
        }
    },
    {
        title: 'Used runs',
        dataIndex: 'runsUsed',
        key: 'runsUsed',
    },
    {
        title: 'Exported rows',
        dataIndex: 'rowsUsed',
        key: 'rowsUsed',
    },
    {
        title: 'Failed runs',
        dataIndex: 'failedRuns',
        key: 'failedRuns',
    }
];

export const UsagePage = () => {

    const [statistics, setStatistics] = useState(undefined);

    useEffect(() => {
        statisticService.fetchCompanyStatistics().then(response => {
            setStatistics(response.data);
        })
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (statistics === undefined) {
        return <Skeleton/>
    } else {
        return <CenteredDesign>
            <Spacer size={"large"}/>
            <PageHeader className="site-page-header-responsive" title={"Account usage"}>
                <Row>
                    <Col sm={16}>
                        <SubTitle>
                            In the current subscription month, apimoon executed {statistics.runsUsed} runs and
                            pulled {statistics.rowsUsed} rows. {statistics.failedRuns > 0 && (<span>{statistics.failedRuns} runs failed.</span>)}
                        </SubTitle>
                        <Spacer/>
                        <SilverTitle>
                            The monthly usage stats will reset on <FormattedDate value={statistics.resetOn}/>.
                        </SilverTitle>
                    </Col>
                </Row>
                <Spacer size={"large"}/>
                <Table dataSource={statistics.companyExportStatistics} columns={columns} size={"large"} rowKey={'name'}
                       pagination={false}
                />
            </PageHeader>
        </CenteredDesign>
    }

}