import React from "react";
import {Tooltip} from "antd";
import {firstUpperCase} from "../../../utils/string.utils";
import {CheckCircleOutlined, InfoCircleOutlined} from "@ant-design/icons";
import PropTypes from "prop-types";

export const StateColumn = ({record}) => {

    if (record.state === 'FAILED') {
        return <Tooltip title={record.message}>{firstUpperCase(record.state)}{" "}<InfoCircleOutlined style={{color: "red"}} /></Tooltip>
    } else {
        return firstUpperCase(record.state)
    }

}

StateColumn.propTypes = {
    record: PropTypes.object.isRequired
}