import React, {useEffect, useState} from "react";
import {Card, Col, Row, Skeleton} from "antd";
import {Spacer} from "../../../components/DesignSystem/Spacer/Spacer";
import {userService} from "../../../services/user.service";
import {useHistory} from "react-router-dom";
import {authenticationService} from "../../../services/authentication.service";
import {CenteredDesign} from "../../../components/DesignSystem/CenteredDesign/CenteredDesign";
import {companyService} from "../../../services/company.service";
import {CollapsePanel} from "../../../components/DesignSystem/CollapsePanel/CollapsePanel";
import {ProfileFormContainer} from "../../../components/apps/settings/profile/ProfileForm.container";
import {PageHeader} from "@ant-design/pro-layout";
import {PasswordForm} from "../../../components/apps/settings/password/PasswordForm";

const ProfilePage = () => {

    const history = useHistory();

    const [user, setUser] = useState(undefined);

    const [company, setCompany] = useState(undefined);

    useEffect(() => {

        userService.me().then((response => {
            setUser((response.data));
            companyService.get().then(response => {
                setCompany(response.data);
            });
        }));

    }, []);

    const deleteAccount = () => {

        userService.delete().then(() => {
            authenticationService.logout().then(() => {
                history.replace(`/user-deleted`);
            });
        });

    }

    return (
        <CenteredDesign>
            <Spacer/>
            <Row justify={'center'}>
                <Col sm={20}>
                    <PageHeader className="site-page-header-responsive" title="Profile">
                        <Card>
                            {company === undefined || user === null ? <Skeleton/> : null}
                            {company !== undefined && user !== undefined && (<>
                                <ProfileFormContainer user={user}/>
                                <Spacer border={true}/>
                                <CollapsePanel title={"Change password"} subtitle={"You can change your password here"}>
                                    <Row>
                                        <Col>
                                            <PasswordForm/>
                                        </Col>
                                    </Row>
                                </CollapsePanel>
                            </>)}
                        </Card>
                    </PageHeader>
                </Col>
            </Row>
        </CenteredDesign>
    );

};

export default ProfilePage;
