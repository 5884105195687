import React from "react";
import LinkOutlined from "@ant-design/icons/lib/icons/LinkOutlined";
import {Tooltip} from "antd";
import PropTypes from "prop-types";


export const ExportDocumentPublicUrl = ({documentPublicUrl}) => {

    if (documentPublicUrl) {
        return <span>, show exported data: <Tooltip title={"Open document"}>
                <a href={documentPublicUrl} title={"Link to document"} target="_blank">
                <LinkOutlined title={"Open document"}/></a>
            </Tooltip>
            </span>
    } else {
        return <></>
    }

}

ExportDocumentPublicUrl.propTypes = {
    documentPublicUrl: PropTypes.string
}