import {Col, Row, Select} from "antd";
import React from "react";
import style from "./SelectStepType.module.css";
import PropTypes from "prop-types";

const {Option} = Select;

const StepTypeItem = ({item}) => {
    return <Row align="middle">
        <Col>
            <img src={`/connectors/${item.icon}.svg`} alt={item.icon} width={20} height={20} align={"left"} className={style.image}/>
        </Col>
        <Col>
            {" "}<span className={style.name}>{item.name}</span>
        </Col>
    </Row>
}

StepTypeItem.propTypes = {
    item: PropTypes.object.isRequired
}

export const SelectStepType = (props) => {

    return <Select {...props} onSelect={props.onSelect}>
        {props.items.map(item => <Option key={item.type} value={item.type}><StepTypeItem item={item}/></Option>)}
    </Select>

}

SelectStepType.propTypes = {
    items: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired
}